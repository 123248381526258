import * as React from "react";

function Svg5Fcfa6B74141Eab7B1Bee9E0UndrawMobilePaymentsVftl(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 712.946 771.135"
      {...props}
    >
      <g data-name="Group 9" transform="translate(-365.054 -89.704)">
        <path
          data-name="Path 2"
          d="M582.481 812.81s-32.21-57.076-80.75-73.524c-20.37-6.9-29-10.711-41.16-28.454"
          fill="none"
          stroke="#e6e6e6"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 5"
          d="M473.68 760.74c7.282-2.521 23.808-23.016 23.808-23.016s-25.677-5.875-32.96-3.354a13.956 13.956 0 009.1 26.371z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 6"
          d="M522.346 789.614c7.6-1.253 27.379-18.654 27.379-18.654s-24.319-10.132-31.923-8.879a13.954 13.954 0 104.545 27.536z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 7"
          d="M451.835 691.186c6.307 4.42 16.617 28.674 16.617 28.674s-26.314-1.39-32.627-5.806a13.958 13.958 0 1116.021-22.859z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 17"
          d="M580.231 811.434s-7.643-79.127-53.394-121.217c-19.194-17.669-32.527-40.769-37.283-66.448a202.206 202.206 0 01-3.338-36.9"
          fill="none"
          stroke="#535461"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 18"
          d="M457.562 560.174c3.491 8.671 29.317 27.483 29.317 27.483s5.563-31.466 2.072-40.137a16.938 16.938 0 10-31.423 12.654z"
          fill="#f37626"
        />
        <path
          data-name="Path 19"
          d="M450.958 618.647c7.643 5.376 39.551 7.134 39.551 7.134s-12.425-29.436-20.068-34.82a16.925 16.925 0 10-19.484 27.68z"
          fill="#f37626"
        />
        <path
          data-name="Path 20"
          d="M485.172 700.246c9.342.781 37.742-13.809 37.742-13.809s-25.589-19.16-34.905-19.916a16.926 16.926 0 10-2.837 33.725z"
          fill="#f37626"
        />
        <path
          data-name="Path 21"
          d="M524.952 756.163c9.053 2.344 39.534-7.236 39.534-7.236s-21.988-23.185-31.033-25.529a16.925 16.925 0 10-8.493 32.767z"
          fill="#f37626"
        />
        <path
          data-name="Path 22"
          d="M524.155 614.172c-3.788 8.552-30.234 26.48-30.234 26.48s-4.5-31.636-.722-40.179a16.926 16.926 0 1130.956 13.7z"
          fill="#f37626"
        />
        <path
          data-name="Path 23"
          d="M569.844 680.152c-6.166 7.024-36.765 16.224-36.765 16.224s5.172-31.534 11.338-38.549a16.92 16.92 0 0125.427 22.328z"
          fill="#f37626"
        />
        <path
          data-name="Path 24"
          d="M605.463 750.379c-4.926 7.966-33.5 22.209-33.5 22.209s-.238-31.95 4.654-39.916a16.938 16.938 0 1128.875 17.716z"
          fill="#f37626"
        />
        <ellipse
          data-name="Ellipse 1"
          cx={356.31}
          cy={23.33}
          rx={356.31}
          ry={23.33}
          transform="translate(365.054 814.18)"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 79"
          d="M994.734 301.91s30-9.61 33.07 0z"
          opacity={0.1}
        />
        <g data-name="Group 8" transform="translate(58.585 36.228)">
          <path
            data-name="Path 26"
            d="M672.799 301.773s6.781-35.573 28.611-35.754l15.946 32.826-10.634 7.334s-23.108.734-33.923-4.406z"
            fill="#d0cde1"
          />
          <path
            data-name="Path 27"
            d="M717.355 298.836l-10.634 7.343s-16.213.515-27.886-2.346a32.161 32.161 0 01-6.037-2.06 2.108 2.108 0 01.124-.572 1.147 1.147 0 010-.134 80.508 80.508 0 014.129-13.066 81.711 81.711 0 012.251-4.883c3.195-6.151 7.687-12.179 13.829-15.164a18.884 18.884 0 017.277-1.907h.973l.343.725 5.4 11.091z"
            opacity={0.502}
          />
          <path
            data-name="Path 28"
            d="M898.538 446.648s22.889-2.2 24.939-.553 15.04 10.09 22.736 4.768 2.565-23.842 2.565-24.577-.725-26.951-.725-26.951l-13.762-18.521-17.233-32.425-35.21-3.481.954 51.5-.181 26.408z"
            fill="#d0cde1"
          />
          <path
            data-name="Path 29"
            d="M898.538 446.648s22.889-2.2 24.939-.553 15.04 10.09 22.736 4.768 2.565-23.842 2.565-24.577-.725-26.951-.725-26.951l-13.762-18.521-17.233-32.425-35.21-3.481.954 51.5-.181 26.408z"
            opacity={0.503}
          />
          <path
            data-name="Path 30"
            d="M696.814 265.104s-1.822 22.374-15.765 22.736c0 0-3.948-2.384-9.26-6.352-12.245-9.165-31.682-26.78-26.312-43.164 7.706-23.47 45.11 19.808 45.11 19.808l2.861 3.223z"
            fill="#fbbebe"
          />
          <path
            data-name="Path 31"
            d="M696.813 265.103s-1.822 22.374-15.765 22.736c0 0-3.948-2.384-9.26-6.352l-1.192-1.526a18.206 18.206 0 007.63 2.05c3.5.076 7.439-1.221 9.966-6.275a79.891 79.891 0 003.815-8.879 26.186 26.186 0 001.411-5.5z"
            opacity={0.1}
          />
          <path
            data-name="Path 32"
            d="M690.766 305.626l-3.138-4.034-10.586-13.59-5.379-6.943a18.119 18.119 0 007.63 2.06c3.5.076 7.439-1.221 9.976-6.285a79.538 79.538 0 003.815-8.879c1.745-4.95 1.707-7.5 1.1-8.354s3.891 3.328 6.056 5.379l.076.076c.62.572 1.02.954 1.02.954l.372.744 5.312 10.624.057.448c.484 3.376 2.277 24.767-16.311 27.8z"
            fill="#e6e6e6"
          />
          <path
            data-name="Path 33"
            d="M800.613 777.646s-15.4 10.09-23.1 8.583-15.593 2.575-15.593 2.575a46.407 46.407 0 01-19.446 12.656c-12.465 4.215-29.879 2.565-39.779-.744-4.692-1.555-5.064-3.576-2.813-6.056 2.508-2.775 8.259-6.123 14.916-10.081 12.646-7.515 26.217-22.374 26.217-22.374s.181-3.662 2.384-5.312a28.46 28.46 0 003.7-3.815c1.965-2.327 3.815-4.768 3.815-4.768l45.3 1.469s.773 3.271 1.373 6.609c.505 2.737.9 5.522.639 6.6-.553 2.375 1.287 4.578 1.287 4.578l.811 7.486z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 34"
            d="M895.438 790.291c-.544 2.193-1.2 6.132-1.5 7.982-.1.629-.153 1.011-.153 1.011s-3.672 8.068-24.023 11.006-20.352-6.79-20.352-6.79.076-1.049.21-2.623c.353-4.292 1.087-12.5 1.621-14.248.734-2.384-.544-12.837-.544-12.837l-.219-10.929-.153-7.63 33.748-3.49a64.525 64.525 0 001.087 7.515 9.538 9.538 0 001.478 3.815c1.831 2.031 2.937 11.005 2.937 11.005s6.578 13.276 5.863 16.213z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 35"
            d="M796.25 749.769s.772 3.271 1.373 6.609c-4.892.9-26.284 4.606-31.634 2.012-6.046-2.937-19.074-4.587-19.074-4.587l.219-.744c1.965-2.327 3.815-4.768 3.815-4.768z"
            opacity={0.1}
          />
          <path
            data-name="Path 36"
            d="M885.139 759.296c-8.583-1.45-27.295 2.06-34.676 3.567l-.153-7.63 33.742-3.49a64.538 64.538 0 001.087 7.553z"
            opacity={0.1}
          />
          <path
            data-name="Path 37"
            d="M904.222 481.305l-.734 25.855s.954 39.426-1.831 45.663-2.756 31.357-2.756 31.357 1.841 11.921-.181 14.477-.553 12.112.954 17.062.172 17.243.172 17.243l-.362 69.314s-2.012 17.968-4.587 20.542a6.008 6.008 0 00-1.459 5.865s-2.2 10.262-1.65 12.1 1.65 6.046-2.565 8.059-3.128 8.44-3.128 8.44c-10.081-2.384-38.682 4.044-38.682 4.044s-5.722-11.74-8.078-19.627 3.491-12.837 7.334-13.352-2.747-7.344-2.747-7.344.734-.954-1.1-7.515.362-23.48.954-27.876 3.3-25.673 3.3-25.673-1.459-26.951-.362-28.057 4.768-11.74 2.937-15.259-4.768-26.408-4.768-27.657-2.384-30.995-2.384-30.995-2.193-20.724-1.65-25.12-1.469-20.714-1.469-20.714c0-13.581-7.877-28.248-7.877-28.248l-.2.525-17.042 44.823s-2.747 13.562-5.14 21.267a139.373 139.373 0 00-3.576 15.917l-.162.954c-.067.41-.124.763-.162 1.03l-.114.744s1.278 9.718-.734 12.837-4.3 12.465-4.3 15.068.172 12.484.172 12.484.744 19.618-1.278 21.821-.954 4.034-.954 4.034 3.309 14.849 1.841 18.7-.362 12.827-.362 12.827-2.909 28.363-3.233 29.85-2.384 12.837-.191 13.752 6.418 6.418 6.418 6.418.954 24.024-2.375 25.855-1.478 11.187-1.478 11.187-26.579 5.14-32.635 2.2-19.074-4.587-19.074-4.587l1.822-6.027c-.1.21-1.316 2.165-2.184-5.341-.954-7.887 6.79-4.034 6.79-4.034s6.046 1.469-3.681-8.068-4.95-19.255-4.4-19.255 1.831-14.115 1.831-14.115 1.65-8.44.362-15.04-2.375-27.142-2.375-27.142.954-15.583-.734-21.267 1.1-15.955 1.65-18.349-1.65-24.2-1.65-24.2 1.841-10.262.734-17.777 1.106-35.21 1.106-35.21l3.3-31.911s2.375-9.9 2.012-13.933 2.747-18.12 2.747-18.12-.362-5.14 1.469-8.8 2.575-15.765 2.575-15.765l17.605-40.351 8.583-35.573 116.446-1.1s.429 3.08 1.125 8.688 1.707 13.914 2.861 24.157a1682.601 1682.601 0 013.2 32.426c3.438 38.845-3.896 45.817-3.896 45.817z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 38"
            d="M893.407 160.207l-27.152 28.983s-34.829-13.943-31.167-18.711c1.421-1.85 1.192-7.63.372-14.1-1.287-10.147-4.034-21.849-4.034-21.849s60.874-9.9 55.734.362c-1.907 3.758-1.545 8.135-.381 12.179a45.776 45.776 0 006.628 13.132z"
            fill="#fbbebe"
          />
          <path
            data-name="Path 39"
            d="M887.161 134.896c-1.907 3.758-2.766 4.12-1.593 8.164-7.963 8.583-18.12 17.882-30.671 17.882a43.422 43.422 0 01-19.436-4.559c-1.287-10.147-4.034-21.849-4.034-21.849s60.874-9.9 55.734.362z"
            opacity={0.1}
          />
          <path
            data-name="Path 40"
            d="M898.538 116.195a43.65 43.65 0 11-87.3 0v-.629a43.642 43.642 0 1187.282.629z"
            fill="#fbbebe"
          />
          <path
            data-name="Path 41"
            d="M904.861 404.123c-5.779.048-25.034.21-46.55.553h-.258l-7.111.12h-.219c-25.511.41-50.651-4.578-60.741-3.624-21.458 2.012 7.7-19.808 7.7-19.808a179.323 179.323 0 0048.877 4.94l6.17-.315c19.5-1.106 39.1-2.937 49.325-6.008.709 5.66 1.701 13.938 2.807 24.142z"
            opacity={0.1}
          />
          <path
            data-name="Path 42"
            d="M904.861 403.026c-5.779 0-25.034.21-46.55.544h-.258l-7.115.124h-.219c-25.511.41-50.651-4.578-60.741-3.634-21.458 2.012 7.7-19.8 7.7-19.8a179.761 179.761 0 0048.877 4.94l6.17-.324c19.5-1.1 39.1-2.937 49.325-6.008a1393.69 1393.69 0 012.811 24.158z"
            fill="#7c5c5c"
          />
          <path
            data-name="Path 43"
            d="M843.157 172.31l-33.742 15.774-26.035 201.343s15.4-6.962 24.2-2.565 35.935.372 35.935.372 19.808-1.106 22.745 1.1 37.4-9.165 37.4-9.165 6.237-13.943 1.469-16.136 1.469-23.842 1.469-23.842l-2.937-145.238-5.865-18.7-15.766-9.543-16.156 12.837-7.63 1.1z"
            opacity={0.1}
          />
          <path
            data-name="Path 44"
            d="M843.157 170.107l-33.742 15.774-26.035 201.361s15.4-6.971 24.2-2.565 35.935.362 35.935.362 19.808-1.1 22.745 1.1 37.4-9.165 37.4-9.165 6.237-13.933 1.469-16.136 1.469-23.842 1.469-23.842l-2.937-145.257-5.865-18.7-15.766-9.542-16.156 12.846-7.63 1.087z"
            fill="#e6e6e6"
          />
          <path
            data-name="Path 45"
            d="M842.604 179.462s9.718 11.549 9.537 13.933-.544 8.068-.544 8.068l-7.343 14.859s-4.034 18.521-4.034 22.374-6.418 37.194-6.418 37.194l-7.7 51.347-9.718 67.3 9.9 17.052 11.74-9.9 15.583-119.936 3.3-23.652s3.481-42.544 3.481-46.941-2.012-15.955-2.012-15.955 2.384-11.006 12.284-13.571-.734-.553-.734-.553l-5.722-4.587-4.95 2.022s-13.713-4.185-16.65.946z"
            opacity={0.1}
          />
          <path
            data-name="Path 46"
            d="M841.507 178.384s9.718 11.549 9.537 13.933-.553 8.068-.553 8.068l-7.334 14.859s-3.986 18.5-3.986 22.345-6.418 37.194-6.418 37.194l-7.754 51.423-9.671 67.255 9.909 17.062 11.683-9.928 15.6-119.908 3.3-23.652s3.481-42.544 3.481-46.95-2.012-15.955-2.012-15.955 2.384-11 12.284-13.571-.734-.544-.734-.544l-5.722-4.587-4.873 2s-13.8-4.2-16.737.956z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 47"
            d="M835.089 159.11s-1.469 1.1-1.831 8.431-5.5 23.842-5.5 23.842 19.074-13.209 28.611-11.74z"
            opacity={0.1}
          />
          <path
            data-name="Path 48"
            d="M835.089 156.907s-1.469 1.1-1.831 8.44-5.5 23.842-5.5 23.842 19.074-13.2 28.611-11.73z"
            fill="#e6e6e6"
          />
          <path
            data-name="Path 49"
            d="M887.17 153.236l-30.079 26.408s25.311 15.04 28.611 18.711 6.599-35.949 6.599-35.949z"
            opacity={0.1}
          />
          <path
            data-name="Path 50"
            d="M888.267 151.042l-30.022 26.389s25.3 15.03 28.611 18.7 6.609-35.945 6.609-35.945z"
            fill="#e6e6e6"
          />
          <path
            data-name="Path 51"
            d="M836.186 159.111s-3.3-1.65-6.418 3.119-8.984 6.962-8.984 6.962-33.379 5.322-34.657 9.718l-15.583.954s-5.5.725-6.056 12.284-3.519 12.627-3.519 12.627-6.418 11.368-5.875 17.233-4.95 8.8-4.95 8.8l22.889 59.6s.734 24.577-3.49 33.56-12.1 48.781-12.1 48.781 3.309-.734 3.119 1.459-4.034 26.227-4.034 26.227-1.1 9.537-2.928 12.837-2.022 20.543-2.022 20.543l-2.937 12.465s6.971 15.593 17.605 13.352 30.013-43.774 30.013-43.774l12.284-44.928 9.537-44.375 2.747-30.07 4.406-66.568 6.418-44.013s7.11-23.67 4.535-26.793z"
            opacity={0.1}
          />
          <path
            data-name="Path 52"
            d="M835.089 156.908s-3.3-1.65-6.418 3.119-8.984 6.971-8.984 6.971-33.379 5.312-34.657 9.718l-15.593.954s-5.5.734-6.046 12.284-3.49 12.655-3.49 12.655-6.418 11.368-5.865 17.233-4.95 8.8-4.95 8.8l22.889 59.6s.734 24.577-3.481 33.56-12.1 48.781-12.1 48.781 3.3-.734 3.119 1.469-4.034 26.217-4.034 26.217-1.1 9.537-2.937 12.837-2.012 20.543-2.012 20.543l-2.914 12.425s6.971 15.593 17.605 13.352 29.889-43.822 29.889-43.822l12.293-44.928 9.537-44.385 2.756-30.07 4.4-66.568 6.418-44.013s7.14-23.614 4.575-26.732z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 53"
            d="M717.356 298.836l-10.634 7.347s-16.213.515-27.886-2.346l-5.913-2.632-1.192-.515 1.221.381c6.132 1.822 10.929 1.783 14.677.525 15.049-5.064 13.352-29.917 12.808-35.544-.057-.563-.1-.954-.124-1.068a.3.3 0 010-.067l.086.143a6.739 6.739 0 00.7.954c.21.238.429.486.668.715l5.312 10.624.057.448z"
            opacity={0.1}
          />
          <path
            data-name="Path 54"
            d="M754.216 227.137l-5.131 1.469-.734 10.491s-6.056-.954-7.706 6.237-4.578 10.491-4.578 10.491-10.09 8.44-10.643 15.583l-6.228 3.815s-14.5-3.376-17.786-9.2c0 0 6.6 47.313-28.611 35.754 0 0 39.426 17.243 41.257 19.627s13.943 9.346 24.939 3.481 13.39-14.887 13.39-14.887 10.634-14.858 11.006-17.062 8.583-4.768 8.583-4.768l15.775-38.151z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 55"
            d="M882.955 175.247l5.312-24.2s2.384 7.887 4.769 9.718l7.887 6.046s7.887 3.672 10.634 3.672 17.605 3.815 19.436 5.865 9.175.553 9.175.553 6.046 2.012 11.368 3.3 7.887 10.09 6.781 13.571a10.576 10.576 0 002.575 9.9c2.193 2.2 3.3 9.356 3.3 9.356a10.729 10.729 0 016.228 5.312c1.841 4.034 1.288 12.837 1.288 12.837l-27.323 38.51s.362 14.859-.734 18.12 3.815 59.415 3.815 59.415l5.722 96.456s-7.515 6.418-9.165 7.153-3.309-34.114-3.309-35.391-42.353-63.449-42.353-63.449-5.722-9.174-5.722-11.187-5.322-20.543-5.322-20.543l-8.072-35.759z"
            opacity={0.1}
          />
          <path
            data-name="Path 56"
            d="M973.976 229.073c0 1.259-.067 2.1-.067 2.1l-27.3 38.481s.382 14.849-.725 18.12c-.639 1.907.763 21.649 2.06 37.852.954 11.7 1.793 21.563 1.793 21.563l2.413 40.913 3.262 55.543s-7.515 6.418-9.165 7.153-3.3-34.114-3.3-35.4-42.363-63.44-42.363-63.44-5.722-9.174-5.722-11.187-5.312-20.543-5.312-20.543l-8.068-35.763 3.662-109.283 3.119-24.2s4.587 7.887 6.971 9.709 7.878 6.056 7.878 6.056 7.887 3.672 10.643 3.672 17.605 3.815 19.436 5.865 9.175.553 9.175.553 6.046 2.012 11.358 3.3 7.887 10.09 6.79 13.561a10.576 10.576 0 002.565 9.909c2.2 2.2 3.3 9.346 3.3 9.346a10.767 10.767 0 016.237 5.331c1.283 2.845 1.407 7.931 1.36 10.789z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 57"
            d="M749.819 717.678c.954 0 39.426 1.469 38.691 7.706s-38.691-7.706-38.691-7.706z"
            opacity={0.1}
          />
          <path
            data-name="Path 58"
            d="M758.441 728.13s25.855 4.215 19.074 8.068-19.074-8.068-19.074-8.068z"
            opacity={0.1}
          />
          <path
            data-name="Path 59"
            d="M854.525 713.644s42.916 3.672 36.5 8.431-21.639 5.5-22.374 5.722-14.126-14.153-14.126-14.153z"
            opacity={0.1}
          />
          <path
            data-name="Path 60"
            d="M849.394 732.536s23.842 4.034 24.567 8.583-24.567-8.583-24.567-8.583z"
            opacity={0.1}
          />
          <path
            data-name="Path 61"
            d="M862.593 622.137s21.82 5.131 23.108 8.583z"
            opacity={0.1}
          />
          <path
            data-name="Path 62"
            d="M778.42 637.911s-11.368 3.3-.362 9.9.362-9.9.362-9.9z"
            opacity={0.1}
          />
          <path
            data-name="Path 63"
            d="M828.852 461.679c1.287 0 21.277-6.6 28.239-3.662s15.593 2.928 15.593 2.928z"
            opacity={0.1}
          />
          <path
            data-name="Path 64"
            d="M831.427 482.612l-.2 1.812-17.042 44.823s-2.747 13.561-5.14 21.267a139.33 139.33 0 00-3.576 15.917l15.138-93.852z"
            opacity={0.1}
          />
          <path
            data-name="Path 65"
            d="M858.054 403.57l-7.115.124h-.219l-4.168-18.483c2.05-.1 4.1-.21 6.17-.334z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 66"
            d="M944.564 381.192l-4.568 3.758-15.256 12.542s-41.266 22.011-52.634 1.106c-10.023-18.416 36.765-31.567 48.028-34.438 1.535-.391 2.4-.582 2.4-.582z"
            fill="#fbbebe"
          />
          <path
            data-name="Path 67"
            d="M768.34 200.92s8.068 3.662 9.9 8.8-9.9-8.8-9.9-8.8z"
            opacity={0.1}
          />
          <path
            data-name="Path 68"
            d="M743.401 255.929s-11.74 14.305-6.971 19.808 6.971-19.808 6.971-19.808z"
            opacity={0.1}
          />
          <path
            data-name="Path 69"
            d="M953.187 208.988s-11.006 1.1-11.368 10.634z"
            opacity={0.1}
          />
          <path
            data-name="Path 70"
            d="M851.225 359.357c1.1-1.1 26.408-38.51 32.273-35.21z"
            opacity={0.1}
          />
          <path
            data-name="Path 71"
            d="M898.538 116.194a43.49 43.49 0 01-5.159 20.6l-.324-.057c-2.766-.582-4.206-3.815-6.323-5.818a8.583 8.583 0 00-9.9-1.392 10.071 10.071 0 00-4.864 9.413c-1.392-5.875-.954-12.121-2.318-18.006s-5.474-11.835-11.091-11.94c-2.422 0-5.255.9-7-.954-1.059-1.1-1.278-2.861-2.07-4.149-4.015-6.5-16.213-3.214-21.229.114-4.826 3.2-10.8 8.66-17 11.521a43.642 43.642 0 1187.282.629z"
            opacity={0.1}
          />
          <path
            data-name="Path 72"
            d="M849.489 102.824c.8 1.326 1.011 3.061 2.07 4.149 1.745 1.812 4.578.887 7 .954 5.617.1 9.728 6.056 11.091 11.94s.954 12.121 2.317 18a10.052 10.052 0 014.873-9.4 8.583 8.583 0 019.89 1.383c2.117 2.012 3.557 5.236 6.323 5.818s5.436-2.07 6.428-4.988.954-6.113 1.583-9.136c1.249-5.722 4.978-10.4 7.048-15.841a28.726 28.726 0 00-.191-20.485c-1.373-3.443-3.424-6.542-4.768-9.985-2.117-5.226-13.857-12.264-16.423-17.243-4.32-8.335-1.535-3.138-9.89 0-3.986 1.5-9.213-3.834-13.256-2.508-5.427 1.736-11.215.429-16.833-.353s-11.807-.858-16.318 2.861c-5.188 4.244-6.609 12.226-11.444 16.89-2.05 1.955-4.6 3.2-6.762 5.016s-4.025 4.5-3.815 7.448c.324 3.815 4.044 7.248 2.67 10.767a8.716 8.716 0 01-2.031 2.68 38.2 38.2 0 00-8.879 15.106c9.813 3.061 20.438-7.754 28.115-12.865 4.971-3.413 17.187-6.665 21.202-.208z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 73"
            d="M944.563 381.192l-4.568 3.758c-4.025-6.676-20.552-20.094-20.552-20.094l.715-.687c1.535-.391 2.4-.582 2.4-.582z"
            opacity={0.1}
          />
          <path
            data-name="Path 74"
            d="M948.969 374.397s-1.64 4.082-3.338 7.63-3.538 6.733-3.634 4.13c-.181-5.14-21.448-22.383-21.448-22.383l2.861-2.8 3.347-3.243z"
            fill="#e6e6e6"
          />
          <path
            data-name="Path 75"
            d="M949.704 347.254l2.413 40.913c-5.722-8.86-21.334-31.567-31.4-30.833l24.4-28.048a32.821 32.821 0 002.794-3.6 3332.041 3332.041 0 001.793 21.568z"
            opacity={0.1}
          />
          <path
            data-name="Path 76"
            d="M948.969 374.397s-1.64 4.082-3.338 7.63c-6.39-8.764-15.183-19.121-22.2-21.057l3.347-3.243z"
            opacity={0.1}
          />
          <path
            data-name="Path 77"
            d="M967.308 226.069l6.6 5.131s11 .191 12.1 9.356 11.187 13.352 11.187 13.352 7.515 7.515 7.515 11 5.722 9.728 5.722 10.271 5.5 11.74 4.768 13.752 5.865 10.824 3.815 14.124a80.862 80.862 0 00-4.406 9.165s-8.583 16.508-13.018 19.627-24.567 29.336-24.567 29.336-21.639 24.939-21.639 32.645c0 0-21.1-35.21-33.56-34.333l24.386-28.058s4.225-4.578 4.406-7.334 11.73-15.4 17.233-15.955c0 0 .954-7.515-1.469-7.7s-4.4-6.056-4.4-6.056-16.136-19.074-16.318-31.176 21.645-37.147 21.645-37.147z"
            fill="#2f2e41"
          />
          <path
            data-name="Path 78"
            d="M977.027 243.464s-15.412 11.368-12.1 15.03 12.1-15.03 12.1-15.03z"
            opacity={0.1}
          />
          <path
            data-name="Path 80"
            d="M800.613 777.646s-15.4 10.09-23.1 8.583-15.593 2.575-15.593 2.575a46.407 46.407 0 01-19.446 12.656c-12.465 4.215-29.879 2.565-39.779-.744-4.692-1.555-5.064-3.576-2.813-6.056 10.767 2.146 34.829 6.409 41.314 3.309 8.431-4.034 20.533-12.837 20.533-12.837s15.784-2.2 17.977-2.2c1.564 0 13.743-5.026 20.619-7.916z"
            opacity={0.1}
          />
          <path
            data-name="Path 82"
            d="M775.951 241.261s0 41.81-3.576 45.939 3.576-45.939 3.576-45.939z"
            opacity={0.1}
          />
          <path
            data-name="Path 83"
            d="M941.263 254.737l5.312 14.94s2.117-14.663-5.312-14.94z"
            opacity={0.1}
          />
          <g data-name="Group 1" opacity={0.1}>
            <path
              data-name="Path 84"
              d="M811.6 94.46c1.345-3.443-2.174-6.809-2.632-10.491a6.9 6.9 0 00-.591 3.319c.238 2.775 2.289 5.379 2.861 7.944a4.343 4.343 0 00.362-.773z"
            />
            <path
              data-name="Path 85"
              d="M872.466 133.952c-1.316-5.789-.954-11.912-2.27-17.691s-5.465-11.845-11.082-11.94c-2.422 0-5.255.887-7.01-.954-1.049-1.087-1.268-2.861-2.06-4.149-4.015-6.5-16.213-3.2-21.239.124-7.506 4.988-17.805 15.412-27.419 13.046-.467 1.116-.9 2.241-1.249 3.4 9.813 3.061 20.438-7.754 28.115-12.865 5.007-3.328 17.224-6.676 21.239-.124.8 1.326 1.011 3.061 2.07 4.149 1.745 1.812 4.578.887 7 .954 5.617.1 9.728 6.056 11.091 11.94s.954 12.121 2.317 18a9.9 9.9 0 01.5-3.882z"
            />
            <path
              data-name="Path 86"
              d="M900.035 127.066c-.954 2.918-3.615 5.589-6.437 4.988s-4.206-3.815-6.313-5.818a8.583 8.583 0 00-9.9-1.383 9.995 9.995 0 00-4.864 8.888 9.537 9.537 0 014.32-5.312 8.583 8.583 0 019.89 1.383c2.117 2.012 3.557 5.236 6.323 5.818s5.436-2.07 6.428-4.988a24.946 24.946 0 001.011-5.264 14.7 14.7 0 01-.458 1.688z"
            />
            <path
              data-name="Path 87"
              d="M908.656 102.086c-2.06 5.436-5.8 10.128-7.038 15.85-.248 1.154-.391 2.337-.534 3.519 1.259-5.722 4.969-10.376 7.029-15.793a28.321 28.321 0 001.7-7.63 27.885 27.885 0 01-1.154 4.053z"
            />
          </g>
          <rect
            data-name="Rectangle 1"
            width={0.839}
            height={6.886}
            rx={0.38}
            transform="rotate(-26.75 832.117 -1265.65)"
            fill="#3a3768"
          />
          <rect
            data-name="Rectangle 5"
            width={27.943}
            height={56.802}
            rx={2.24}
            transform="rotate(-26.75 818.745 -1201.365)"
            fill="#3a3768"
          />
          <path
            data-name="Path 88"
            d="M648.356 231.362a.954.954 0 00-.439 1.345l.1.181a.954.954 0 01-.448 1.345l-10.576 5.331a.954.954 0 01-1.345-.439l-.1-.191a.954.954 0 00-1.345-.439l-2.06 1.03a1.907 1.907 0 00-.849 2.575l22.5 44.661a1.907 1.907 0 002.575.849l18.454-9.3a1.907 1.907 0 00.849-2.565l-22.471-44.668a1.907 1.907 0 00-2.565-.839z"
            fill="#f37626"
          />
        </g>
        <path
          data-name="Rectangle 10"
          opacity={0.1}
          d="M552.366 791.9h36.468v5.775h-36.468z"
        />
        <path
          data-name="Path 95"
          d="M670.174 271.145v113.753a11.393 11.393 0 01-11.413 11.413h-12.258V259.742h12.257a11.393 11.393 0 0111.413 11.4z"
          fill="#d0cde1"
        />
        <path
          data-name="Path 96"
          d="M660.868 274.321v107.915a12.706 12.706 0 01-2.1.171h-1.754V274.15h1.758a12.705 12.705 0 012.096.171z"
          opacity={0.2}
        />
        <path
          data-name="Rectangle 7"
          fill="#d0cde1"
          d="M571.868 436.448h44.192v354.34h-44.192z"
        />
        <path
          data-name="Path 97"
          d="M540.829 785.23h101.183a41.887 41.887 0 0141.887 41.887v6.443H498.942v-6.443a41.887 41.887 0 0141.887-41.887z"
          fill="#d0cde1"
        />
        <path
          data-name="Rectangle 11"
          opacity={0.1}
          d="M571.868 485.23h44.192v42.504h-44.192z"
        />
        <path
          data-name="Rectangle 12"
          opacity={0.1}
          d="M571.868 482.338h44.192v42.504h-44.192z"
        />
        <path
          data-name="Rectangle 13"
          fill="#d0cde1"
          d="M567.772 483.779h52.888v42.504h-52.888z"
        />
        <path
          data-name="Path 98"
          d="M639.865 409.752v17.64a16.549 16.549 0 01-16.55 16.549h-58.661a16.549 16.549 0 01-16.549-16.549v-17.64a16.539 16.539 0 0116.549-16.528h58.661a16.539 16.539 0 0116.549 16.528z"
          fill="#d0cde1"
        />
        <path
          data-name="Path 99"
          d="M639.865 409.752v1.8a57.725 57.725 0 01-91.791 0v-1.8a16.539 16.539 0 0116.549-16.528h58.662a16.539 16.539 0 0116.58 16.528z"
          opacity={0.1}
        />
        <path
          data-name="Path 100"
          d="M540.911 245.395h106.117a4.662 4.662 0 014.662 4.662v132.762a50.027 50.027 0 01-50.027 50.027h-15.387a50.027 50.027 0 01-50.027-50.027V250.057a4.662 4.662 0 014.662-4.662z"
          fill="#d0cde1"
        />
        <path
          data-name="Path 101"
          d="M560.101 193.053h66.9c9.81 0 17.763 6.187 17.763 13.82v39.594c0 2.751-2.866 4.98-6.4 4.98h-89.625c-3.535 0-6.4-2.23-6.4-4.98v-39.6c0-7.632 7.953-13.82 17.763-13.82z"
          fill="#d0cde1"
        />
        <path
          data-name="Rectangle 14"
          opacity={0.2}
          d="M556.775 223.371h71.949v5.31h-71.949z"
        />
        <path
          data-name="Rectangle 15"
          opacity={0.1}
          d="M548.084 262.438h91.77v66.597h-91.77z"
        />
        <path
          data-name="Rectangle 16"
          fill="#f37626"
          d="M548.084 260.987h91.77v66.597h-91.77z"
        />
        <g
          data-name="Group 2"
          transform="translate(548.599 338.997)"
          opacity={0.1}
        >
          <rect
            data-name="Rectangle 17"
            width={20.645}
            height={7.369}
            rx={0.79}
          />
          <rect
            data-name="Rectangle 18"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(23.362)"
          />
          <rect
            data-name="Rectangle 19"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(46.724)"
          />
          <rect
            data-name="Rectangle 20"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(70.096)"
          />
          <rect
            data-name="Rectangle 21"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(0 14.429)"
          />
          <rect
            data-name="Rectangle 22"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(23.362 14.429)"
          />
          <rect
            data-name="Rectangle 23"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(46.724 14.429)"
          />
          <rect
            data-name="Rectangle 24"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(0 28.497)"
          />
          <rect
            data-name="Rectangle 25"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(23.362 28.497)"
          />
          <rect
            data-name="Rectangle 26"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(46.724 28.497)"
          />
          <rect
            data-name="Rectangle 27"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(0 44.017)"
          />
          <rect
            data-name="Rectangle 28"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(23.362 44.017)"
          />
          <rect
            data-name="Rectangle 29"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(46.724 44.017)"
          />
          <rect
            data-name="Rectangle 30"
            width={20.645}
            height={7.369}
            rx={0.79}
            transform="translate(70.096 14.429)"
          />
          <rect
            data-name="Rectangle 31"
            width={18.298}
            height={26.264}
            rx={1.97}
            transform="translate(71.362 27.705)"
          />
        </g>
        <rect
          data-name="Rectangle 32"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(548.599 337.546)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 33"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(571.961 337.546)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 34"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(595.323 337.546)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 35"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(618.695 337.546)"
          fill="#2f2e41"
        />
        <rect
          data-name="Rectangle 36"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(548.599 351.975)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 37"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(571.961 351.975)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 38"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(595.323 351.975)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 39"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(548.599 366.043)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 40"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(571.961 366.043)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 41"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(595.323 366.043)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 42"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(548.599 381.563)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 43"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(571.961 381.563)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 44"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(595.323 381.563)"
          fill="currentColor"
        />
        <rect
          data-name="Rectangle 45"
          width={20.645}
          height={7.369}
          rx={0.79}
          transform="translate(618.695 351.975)"
          fill="#2f2e41"
        />
        <rect
          data-name="Rectangle 46"
          width={18.298}
          height={26.264}
          rx={1.97}
          transform="translate(619.961 365.261)"
          fill="#f37626"
        />
        <path
          data-name="Rectangle 47"
          fill="currentColor"
          d="M593.841 319.495h9.89v1.184h-9.89z"
        />
        <path
          data-name="Rectangle 48"
          fill="currentColor"
          d="M607.693 319.495h9.89v1.184h-9.89z"
        />
        <path
          data-name="Rectangle 49"
          fill="currentColor"
          d="M621.546 319.495h9.89v1.184h-9.89z"
        />
      </g>
    </svg>
  );
}

export default Svg5Fcfa6B74141Eab7B1Bee9E0UndrawMobilePaymentsVftl;
