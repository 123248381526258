import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import BackgroundSection from '../../components/sections/BackgroundSection/index'
import useBackgroundImageStyles from '../../components/sections/BackgroundSection/Image'
import SectionHeader from '../../components/sections/SectionHeader'
import Link from '../../components/sections/Link'
import EatingTogether from './EatingTogether'
import useImageStyles from '../../utils/imageStyles'
import Body from '../../components/sections/Section/Body'
import Background from '../../components/sections/BackgroundSection/Background1'

const StyledImage = useImageStyles(EatingTogether)

const BackgroundImage = useBackgroundImageStyles(Background)

class Feature1 extends Component {
  render() {
    return (
      <BackgroundSection>
        <BackgroundImage />
        <Body>
          <SectionHeader>
            <Typography variant="h2">Contactless Dine-In</Typography>
            <Typography variant="subtitle1">
              Air Dining helps eliminate certain touchpoints that cause direct
              or indirect human contact. You don't need anything apart from your
              phone.
            </Typography>
          </SectionHeader>
          <Link href="/about">Learn about us</Link>
        </Body>
        <StyledImage />
      </BackgroundSection>
    )
  }
}

export default Feature1
