import React, { Component } from 'react'
import { Button, withStyles, Typography } from '@material-ui/core'

import {
  Section as BaseSection,
  getStyles,
} from '../../components/sections/Section'
import Container from '../../components/Container'
import {
  getStyles as getBodyStyles,
  Body as BaseBody,
} from '../../components/sections/Section/Body'
import useImageStyles from '../../utils/imageStyles'
import Image from './Data'

const sectionStyles = (theme) => {
  const styleClasses = getStyles(theme)
  return {
    ...styleClasses,
    section: {
      ...styleClasses.section,
      alignItems: 'stretch !important',
    },
  }
}

const Section = withStyles(sectionStyles)(BaseSection)

const bodyStyles = (theme) => {
  const styleClasses = getBodyStyles(theme)
  return {
    ...styleClasses,
    content: {
      ...styleClasses.content,
      height: 'auto',
    },
  }
}

const Body = withStyles(bodyStyles)(BaseBody)

const styles = (theme) => ({
  content: {
    backgroundColor: theme.palette.primary.main,
    padding: '0.75rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '1.5625rem 3.75rem',
    },
  },
  title: {
    fontSize: '2.6875rem',
    color: theme.palette.primary.contrastText,
    margin: '1.25rem 0 0.625rem',
    letterSpacing: '-0.03em',
    lineHeight: 1.1,
  },
  button: {
    marginTop: '2.5rem',
    padding: '1.25rem 2.1875rem',
    color: theme.palette.primary.main,
    borderRadius: '0.25rem',
    boxShadow: 'none',
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '15.625rem',
  },
})

const StyledImage = useImageStyles(Image)

class CallToAction extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container>
        <Section>
          <StyledImage />
          <Body>
            <div className={classes.content}>
              <Typography variant="h1" className={classes.title}>
                Own your data. Grow your business safely and smartly.
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                href="/contact"
              >
                <Typography variant="button">Contact us</Typography>
              </Button>
            </div>
          </Body>
        </Section>
      </Container>
    )
  }
}

export default withStyles(styles)(CallToAction)
