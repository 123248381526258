import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

const backgroundImageStyles = () => {
  return createStyles({
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '96%',
      height: '100%',
      zIndex: -1,
    },
  })
}

const useBackgroundImageStyles = (Image) => {
  const ComponentWithStyles = ({ classes }) => (
    <Image className={classes.image} />
  )
  return withStyles(backgroundImageStyles)(ComponentWithStyles)
}

export default useBackgroundImageStyles
