import { createStyles, withStyles, Typography } from '@material-ui/core'
import React, { Component } from 'react'

const styles = (theme) =>
  createStyles({
    item: {
      listStyle: 'none',
    },
    image: {
      width: '100%',
      height: 'auto',
      marginBottom: '2.5rem',
    },
  })

class Item extends Component {
  render() {
    const { classes, Image, title, description } = this.props
    return (
      <li className={classes.item}>
        <Image className={classes.image} />
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {description}
        </Typography>
      </li>
    )
  }
}

export default withStyles(styles)(Item)
