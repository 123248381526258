import React, { Component } from 'react'

import Layout from '../components/Layout'
import Header from './Header'
import Feature1 from './Feature1'
import Feature2 from './Feature2'
import RewardLoyalty from './RewardLoyalty'
import HowItWorks from './HowItWorks'
import CallToAction from './CallToAction'

export class Home extends Component {
  render() {
    return (
      <Layout>
        <Header />
        <Feature1 />
        <HowItWorks />
        <Feature2 />
        <RewardLoyalty />
        <CallToAction />
      </Layout>
    )
  }
}

export default Home
