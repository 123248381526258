import { createStyles, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

import Item from './Item'
import Order from './Order'
import Pay from './Pay'
import Scan from './Scan'
import Line from './Line'

const styles = (theme) =>
  createStyles({
    list: {
      paddingLeft: 0,
      gridRowGap: '2rem',
      gridTemplateRows: 'auto auto auto',
      gridTemplateColumns: '1fr',
      display: 'grid',
      maxWidth: '31.875rem',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 0.3fr 1fr 0.3fr 1fr',
        gridTemplateRows: 'auto',
        gridAutoColumns: '1fr',
        maxWidth: 'none',
      },
    },
  })

class List extends Component {
  render() {
    const { classes } = this.props
    return (
      <ul className={classes.list}>
        <Item
          title="Scan"
          description="Scan the QR code on your table"
          Image={Order}
        />
        <Line />
        <Item
          title="Order"
          description="Order online from your phone. No app required."
          Image={Scan}
        />
        <Line />
        <Item
          title="Pay"
          description="Pay using UPI, Card or Cash. That's it"
          Image={Pay}
        />
      </ul>
    )
  }
}

export default withStyles(styles)(List)
