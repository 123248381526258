import * as React from 'react'

function Svg5Fcfa4062E61F646F5Acf23FUndrawMobilePayReSjb81(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 856.122 674"
      {...props}
    >
      <path
        d="M421.325 153.136h-3.541V56.139A56.14 56.14 0 00361.644 0h-205.5a56.14 56.14 0 00-56.14 56.139V588.27a56.14 56.14 0 0056.14 56.14h205.5a56.14 56.14 0 0056.14-56.14V222.18h3.54z"
        fill="#3f3d56"
      />
      <path
        d="M363.487 15.933h-26.825a19.918 19.918 0 01-18.441 27.44H200.492a19.918 19.918 0 01-18.441-27.44h-25.055a41.924 41.924 0 00-41.924 41.924V589.21a41.924 41.924 0 0041.924 41.924h206.491a41.924 41.924 0 0041.924-41.924V57.857a41.924 41.924 0 00-41.924-41.924zm-4.507 246.498a10.891 10.891 0 01-10.859 10.859h-52.486a10.891 10.891 0 01-10.86-10.86v-2.714a10.891 10.891 0 0110.86-10.86h52.486a10.891 10.891 0 0110.86 10.86zm5.882-47.962a10.891 10.891 0 01-10.859 10.86H165.777a10.891 10.891 0 01-10.86-10.86v-2.715a10.891 10.891 0 0110.86-10.859h188.226a10.891 10.891 0 0110.86 10.86zm0-47.961a10.891 10.891 0 01-10.859 10.859H165.777a10.891 10.891 0 01-10.86-10.86v-2.714a10.891 10.891 0 0110.86-10.86h188.226a10.891 10.891 0 0110.86 10.86zm0-47.962a10.891 10.891 0 01-10.859 10.86H165.777a10.891 10.891 0 01-10.86-10.86v-2.715a10.891 10.891 0 0110.86-10.859h188.226a10.891 10.891 0 0110.86 10.86z"
        fill="white"
      />
      <circle cx={169.849} cy={213.564} r={6.335} fill="currentColor" />
      <circle cx={190.663} cy={213.564} r={6.335} fill="currentColor" />
      <circle cx={211.476} cy={213.564} r={6.335} fill="currentColor" />
      <circle cx={232.29} cy={213.564} r={6.335} fill="currentColor" />
      <circle cx={300.16} cy={261.526} r={6.335} fill="currentColor" />
      <circle cx={320.973} cy={261.526} r={6.335} fill="currentColor" />
      <circle cx={341.787} cy={261.526} r={6.335} fill="currentColor" />
      <path
        d="M366.319 343.601H151.652a19.016 19.016 0 00-19.016 19.016v104.59a19.016 19.016 0 0019.016 19.016h214.667a19.016 19.016 0 0019.015-19.016v-104.59A19.016 19.016 0 00366.32 343.6zM146.34 356.573c.424-.077 10.475-1.787 18.799 3.221 8.323 5.007 11.52 14.69 11.653 15.1l.336 1.044-1.08.192a28.669 28.669 0 01-4.658.342 27.738 27.738 0 01-14.142-3.564c-8.323-5.007-11.52-14.69-11.652-15.1l-.337-1.044zm46.598 97.105h-38.287a10.53 10.53 0 010-21.058h38.287a10.53 10.53 0 010 21.058zm174.209-1.914a11.52 11.52 0 01-11.487 11.486h-28.715a11.52 11.52 0 01-11.487-11.486v-17.23a11.52 11.52 0 0111.487-11.486h28.715a11.52 11.52 0 0111.487 11.486z"
        fill="#f37626"
      />
      <path
        d="M336.204 448.213H324.66a3.108 3.108 0 000 6.217h11.545a3.108 3.108 0 000-6.217zM357.52 448.213h-11.546a3.108 3.108 0 000 6.217h11.546a3.108 3.108 0 000-6.217zM336.203 439.438h-11.546a3.108 3.108 0 000 6.217h11.546a3.108 3.108 0 000-6.217zM357.518 439.438h-11.546a3.108 3.108 0 000 6.217h11.546a3.108 3.108 0 000-6.217zM336.203 430.841h-11.546a3.108 3.108 0 000 6.217h11.546a3.108 3.108 0 000-6.217zM357.518 430.841h-11.546a3.108 3.108 0 000 6.217h11.546a3.108 3.108 0 000-6.217z"
        fill="#3f3d56"
      />
      <path fill="#e6e6e6" d="M487 383h28v28h-28z" />
      <path d="M454 400h46v46h-46zm44 2h-42v42h42z" fill="#3f3d56" />
      <path fill="#e6e6e6" d="M0 152h28v28H0z" />
      <path d="M61 163H15v-46h46zm-44-2h42v-42H17z" fill="#3f3d56" />
      <path
        fill="#ffb8b8"
        d="M653.077 661.417h-12.26l-5.832-47.288h18.094l-.002 47.288z"
      />
      <path
        d="M632.06 657.914h23.644V672.8h-38.53a14.887 14.887 0 0114.886-14.886z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M714.077 661.417h-12.26l-5.832-47.288h18.094l-.002 47.288z"
      />
      <path
        d="M693.06 657.914h23.644V672.8h-38.53a14.887 14.887 0 0114.886-14.886z"
        fill="#2f2e41"
      />
      <path
        d="M591.576 492.303a10.743 10.743 0 014.51-15.843l41.676-114.866 20.507 11.191-47.584 109.77a10.8 10.8 0 01-19.11 9.748zM707.815 513.887a10.743 10.743 0 01-2.893-16.216L692.01 376.16l23.338 1.067 5.365 119.52a10.8 10.8 0 01-12.9 17.14z"
        fill="#ffb8b8"
      />
      <circle cx={670.584} cy={315.756} r={24.561} fill="#ffb8b8" />
      <path
        d="M707.658 486.252h-74.047l.09-.576c.132-.861 13.196-86.439 3.561-114.436a11.813 11.813 0 016.07-14.583c13.771-6.486 40.207-14.471 62.52 4.909a28.234 28.234 0 019.459 23.396z"
        fill="#ccc"
      />
      <path
        d="M647.833 411.884l-33.337-6.763 15.626-37.03a13.997 13.997 0 0127.106 6.998zM690.529 424.442l-2.004-45.942c-1.52-8.636 3.424-16.8 11.027-18.134 7.605-1.33 15.032 4.66 16.558 13.36l7.533 42.927z"
        fill="#ccc"
      />
      <path
        d="M705.194 480.752c11.915 45.377 13.215 103.07 10 166l-16-2-29-120-16 122-18-1c-5.377-66.03-10.613-122.715-2-160zM686.767 334.738c-4.582 4.88-13.09 2.26-13.688-4.407a8.055 8.055 0 01.01-1.556c.309-2.954 2.015-5.635 1.606-8.754a4.59 4.59 0 00-.84-2.149c-3.651-4.889-12.222 2.187-15.668-2.239-2.113-2.714.37-6.987-1.25-10.02-2.14-4.004-8.48-2.029-12.455-4.222-4.422-2.439-4.158-9.225-1.247-13.352 3.551-5.034 9.776-7.72 15.924-8.107s12.253 1.275 17.992 3.512c6.521 2.541 12.988 6.053 17 11.787 4.881 6.973 5.35 16.348 2.91 24.502-1.485 4.96-6.552 11.02-10.294 15.005z"
        fill="#2f2e41"
      />
      <path d="M855.122 674h-381a1 1 0 010-2h381a1 1 0 010 2z" fill="#3f3d56" />
    </svg>
  )
}

export default Svg5Fcfa4062E61F646F5Acf23FUndrawMobilePayReSjb81
