import React, { Component } from 'react'
import { createStyles, Typography, withStyles } from '@material-ui/core'
import '../../../node_modules/animate.css/animate.min.css'

import MobilePay from './MobilePay'
import Button from '../../components/sections/Link'
import Container from '../../components/Container'

const styles = (theme) => {
  const contentWidth = '50%'
  const flexBreakpoint = 'md'

  return createStyles({
    header: {
      padding: '0 0.5996rem 3.75rem',
      textAlign: 'center',
      overflow: 'hidden',
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        paddingTop: '6.25rem',
        paddingBottom: '8.75rem',
      },
    },
    emphasizedTitle: {
      color: theme.palette.primary.main,
    },
    body: {
      marginBottom: '2.8125rem',
    },
    title: {
      maxWidth: '31.25rem',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up(flexBreakpoint)]: {
        marginLeft: 0,
      },
    },
    image: {
      marginTop: '3.75rem',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.up(flexBreakpoint)]: {
        width: contentWidth,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up(flexBreakpoint)]: {
        justifyContent: 'flex-start',
      },
    },
    content: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        flexBasis: contentWidth,
        width: contentWidth,
        paddingRight: '2.5rem',
      },
    },
  })
}

class Header extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container>
        <header className={classes.header}>
          <div className={classes.content}>
            <div className={classes.body}>
              <Typography
                variant="h1"
                className={`${classes.title} animate__animated animate__slideInLeft animate__faster`}
              >
                Best{' '}
                <span className={classes.emphasizedTitle}>contactless</span>{' '}
                dine-in solution. Scan,{' '}
                <span className={classes.emphasizedTitle}>order</span> and pay.
              </Typography>
              <Typography
                variant="subtitle1"
                className="animate__animated animate__slideInLeft animate__fast"
              >
                The all-in-one solution for your contactless dine-in, delivery
                and takeaway services.
              </Typography>
            </div>
            <div
              className={`${classes.buttonContainer} animate__animated animate__slideInLeft`}
            >
              <Button href="/contact">
                <Typography variant="button">Request a demo</Typography>
              </Button>
            </div>
          </div>
          <MobilePay
            className={`${classes.image} animate__animated animate__slideInRight`}
          />
        </header>
      </Container>
    )
  }
}

export default withStyles(styles)(Header)
