import { createStyles, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    line: {
      width: '0.0625rem',
      height: '100%',
      minHeight: '6.25rem',
      borderStyle: 'none dashed none none',
      borderWidth: '0.0813rem',
      borderColor: '#d1cdcd',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        height: '0.0625rem',
        minHeight: 'auto',
        borderStyle: 'dashed none none none',
      },
    },
  })

class Line extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.line} />
      </div>
    )
  }
}

export default withStyles(styles)(Line)
