import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import BackgroundSection from '../../components/sections/BackgroundSection/index'
import useBackgroundImageStyles from '../../components/sections/BackgroundSection/Image'
import SectionHeader from '../../components/sections/SectionHeader'
import Link from '../../components/sections/Link'
import Image from './Image'
import useImageStyles from '../../utils/imageStyles'
import Body from '../../components/sections/Section/Body'
import Background from './../../components/sections/BackgroundSection/Background1'

const StyledImage = useImageStyles(Image)

const BackgroundImage = useBackgroundImageStyles(Background)

class RewardLoyalty extends Component {
  render() {
    return (
      <BackgroundSection>
        <BackgroundImage />
        <Body>
          <SectionHeader>
            <Typography variant="h2">Reward Loyalty</Typography>
            <Typography variant="subtitle1">
              Customers get exciting offers and loyalty benefits. Run campaigns,
              build a brand with your customer data.
            </Typography>
          </SectionHeader>
          <Link href="tel:+919520061046">Call +91 9520061046</Link>
        </Body>
        <StyledImage />
      </BackgroundSection>
    )
  }
}

export default RewardLoyalty
