import React, { Component } from 'react'
import { createStyles, Typography, withStyles } from '@material-ui/core'

import Section from '../../components/sections/ContentSection'
import Container from '../../components/Container'
import List from './List'

const styles = createStyles({
  content: {
    width: '100%',
  },
})

export class HowItWorks extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container>
        <Section>
          <div className={classes.content}>
            <Typography variant="h2" align="center">
              How it works.
            </Typography>
            <List />
          </div>
        </Section>
      </Container>
    )
  }
}

export default withStyles(styles)(HowItWorks)
