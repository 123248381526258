import { createStyles, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

import Container from '../../Container'
import { getStyles, Section as BaseSection } from '../Section/index'

const sectionStyles = (theme) => {
  const changeBackgroundBreakpoint = 'md'
  const styleClasses = getStyles(theme)
  return createStyles({
    ...styleClasses,
    section: {
      backgroundColor: 'rgb(254, 251, 247)',
      padding: '6.25rem 0',
      [theme.breakpoints.up(changeBackgroundBreakpoint)]: {
        background: 'none',
        padding: '8.75rem 0',
      },
    },
  })
}

const Section = withStyles(sectionStyles)(BaseSection)

const styles = (theme) => {
  const styleClasses = getStyles(theme)
  return createStyles({
    content: {
      ...styleClasses.section,
    },
    background: {
      overflowX: 'hidden',
      position: 'relative',
    },
  })
}

class BackgroundContainer extends Component {
  render() {
    const { classes, children } = this.props
    return (
      <Section>
        <div className={classes.background}>
          <Container>
            <div className={classes.content}>{children}</div>
          </Container>
        </div>
      </Section>
    )
  }
}

export default withStyles(styles)(BackgroundContainer)
