import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import SectionHeader from '../../components/sections/SectionHeader'
import Link from '../../components/sections/Link'
import BeautifulMenu from './BeautifulMenu'
import useImageStyles from '../../utils/imageStyles'
import Body from '../../components/sections/Section/Body'
import Container from '../../components/Container'
import ContentSection from '../../components/sections/ContentSection'

const StyledImage = useImageStyles(BeautifulMenu)

class Feature2 extends Component {
  render() {
    return (
      <Container>
        <ContentSection>
          <StyledImage />
          <Body>
            <SectionHeader>
              <Typography variant="h2">Beautifully crafted menu</Typography>
              <Typography variant="subtitle1">
                Give your customers the best in class digital experience.
                That'll increase their hunger and your sales.
              </Typography>
            </SectionHeader>
            <Link href="/contact">Request a demo</Link>
          </Body>
        </ContentSection>
      </Container>
    )
  }
}

export default Feature2
